import React from 'react';

export default class Modal extends React.Component {

    constructor(props) {
        super(props);
    }

    handleBackdropClick() {
        if (this.props.onClose instanceof Function) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <div
                className={`modal-backdrop${this.props.show ? '' : ' hide'}`}
                onClick={this.handleBackdropClick.bind(this)}
            >
                <div
                    className="modal flex flex-col justify-center items-center"
                >
                    {this.props.children}
                </div>
            </div>
        );
    }

}
