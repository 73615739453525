import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import ReturnLink from '../components/InfosPages/ReturnLink';
import Modal from '../components/modal';

const ContactButton = styled.button`
  background: linear-gradient(320.07deg, #2f8975 24.52%, #0078a3 72.78%);
  border-radius: 50px;
  color: white;
  min-width: 6rem;
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
  padding: 8px 40px;
  margin-top: 1.5rem;
`;

export default class contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hubspot: {
                portalId: process.env.HUBSPOT_PORTAL_ID,
                formId: process.env.HUBSPOT_FORM_ID,
                region: process.env.HUBSPOT_REGION,
                formRef: React.createRef(),
                userData: null,
                onAfterInit: (formElementJQuery) => {
                    if (this.state.hubspot.formRef.current && this.state.hubspot.userData) {
                        const fields = this.state.hubspot.formRef.current.querySelectorAll('.hs-input');
                        if (fields.length === this.state.hubspot.userData.length) {
                            setTimeout(() => {
                                for (let i = 0; i < fields.length; ++i) {
                                    fields[i].value = this.state.hubspot.userData[i];
                                }
                            }, 0);
                        }
                    }
                },
                onSubmit: (formElementJQuery) => {
                    const formValueMap = formElementJQuery.serializeArray();
                    formValueMap.pop(); // removes hubspot's internal field
                    this.state.hubspot.userData = formValueMap.map((fieldData) => fieldData.value);
                    this.setState({ ...this.state });
                },
                onSubmitted: (submitConfirmationDivElementJQuery) => {
                    this.state.modals.success.show = true;
                    this.setState({ ...this.state });
                    this.createHubspotForm();
                },
                css: {
                    formClass: 'contact-form',
                    submitButtonClass: 'contact-submit-button',
                    fieldErrorClass: 'field-error',
                    fieldErrorMessageClass: 'field-error-message',
                    get rules() {
                        return `
                            .${this.formClass} h1 {
                                display: none;
                            }
                        `;
                    }
                }
            },
            modals: {
                success: {
                    show: false,
                },
                error: {
                    show: false,
                    value: null
                }
            }
        };
        this.loadHubspot();
    }

    handleModalClose(modalName) {
        this.state.modals[modalName].show = false;
        this.setState({ ...this.state });
    }

    loadHubspot() {
        if (typeof document !== 'undefined') {
            const jqueryScript = document.createElement('script');
            jqueryScript.defer = true;
            jqueryScript.src = "https://code.jquery.com/jquery-3.6.0.min.js";
            document.head.appendChild(jqueryScript);
            const hubspotScript = document.createElement('script');
            hubspotScript.defer = true;
            hubspotScript.onload = () => {
                this.createHubspotForm();
            };
            hubspotScript.src = "//js.hsforms.net/forms/v2.js";
            document.head.appendChild(hubspotScript);
        }
    }

    createHubspotForm() {
        if (typeof window !== 'undefined' && window.hbspt) {
            const hubspot = window.hbspt;
            hubspot.forms.create({
                portalId: this.state.hubspot.portalId,
                formId: this.state.hubspot.formId,
                region: this.state.hubspot.region,
                target: `#${this.state.hubspot.formRef.current.id}`,
                onFormReady: this.state.hubspot.onAfterInit.bind(this),
                onFormSubmit: this.state.hubspot.onSubmit.bind(this),
                onFormSubmitted: this.state.hubspot.onSubmitted.bind(this),
                // inlineMessage: ' ',
                cssClass: this.state.hubspot.css.formClass,
                submitButtonClass: this.state.hubspot.css.submitButtonClass,
                errorClass: this.state.hubspot.css.fieldErrorClass,
                errorMessageClass: this.state.hubspot.css.fieldErrorMessageClass,
                cssRequired: this.state.hubspot.css.rules
            });
        }
        else {
            setTimeout(this.createHubspotForm, 100);
        }
    }

    render() {
        return (
            <Layout>
                <ReturnLink />
                <div className="MainWrapper / -lg:px-16 pb-36 flex flex-col items-start justify-start max-w-full px-3 mt-6">
                    <h1 className="my-5 text-2xl font-medium text-green-700">Nous contacter</h1>
                    <hr className="background-green-700 w-full h-2" />
                    <div
                        id="hubspot-form-container"
                        className="form-container contact-form-container"
                        ref={this.state.hubspot.formRef}
                    ></div>
                </div>
                <Modal
                    show={this.state.modals.success.show}
                    onClose={this.handleModalClose.bind(this, 'success')}
                >
                    <div
                        className="modal-content"
                    >
                        <p>Votre message a bien été envoyé !</p>
                        <Link to="/">
                            <ContactButton>Retour</ContactButton>
                        </Link>
                    </div>
                </Modal>
                <Modal
                    show={this.state.modals.error.show}
                    onClose={this.handleModalClose.bind(this, 'error')}
                >
                    <div
                        className="modal-content"
                    >
                        <p>Erreur lors de l'envoie de votre message.</p>
                        <p>Veuillez réessayer plus tard.</p>
                        <Link to="/">
                            <ContactButton>Retour</ContactButton>
                        </Link>
                    </div>
                </Modal>
            </Layout>
        );
    }
}
